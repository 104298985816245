:root {
    --bs-body-bg: #{$white};
    --bs-body-color: #{$dark};
    --bs-secondary-bg: #{$gray-100};
    --bs-border-color: #{$gray-200};
    --bs-icon-bg: #{$white};
    --bs-footer-bg: #272a33;
    --bs-footer-alt-bg: #2e313a;
    --bs-border-bottom-color:#ecf0f5;


    --bs-bg-light: #fbfbfb;

    --bs-navbar-bg: #0c1016;
}