/* 
// 14. Footer
*/

.social-icon {
    background-color: $gray-200;
    color: $gray-600;
    transition: all 0.3s;
    &:hover {
        background: $primary;
        color: $white;
        box-shadow: 0 0 0px 5px rgba($primary, 0.12);
    }
}

.footer {
    padding-top: 60px;
    padding-bottom: 20px;
    position: relative;
    background: $dark;
    .footer-sub-menu {
        li {
            margin: 14px 0;
            .footer-link {
                color: rgba($white, 0.5);
                transition: all 0.3s;
                &:hover {
                    color: $white;
                    margin-left: 5px;
                }
            }
        }
    }
}

[data-bs-theme="dark"]{
    .footer{
        background: var(--bs-footer-bg-dark) !important;
    }
}