[data-bs-theme=dark] {
    $gray-100: #18202a;
    $gray-200: #1a2432;
    $gray-300: #1a2532;
    $gray-400: #878a99;
    $gray-500: #adb5bd;
    $gray-600: #bfc8e2;
    $gray-700: #ced4da;
    $gray-800: #eff2f7;
    $gray-900: #f3f6f9;

    $grays: ("100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900 );

    // Prefix for :root CSS variables
    @each $color,
    $value in $grays {
        --bs-gray-#{$color}: #{$value};
    }

    --bs-body-color: #{$gray-800};
    --bs-body-bg: #{$gray-100};
    --bs-secondary-bg: #{$gray-200};
    --bs-border-color: #{$gray-300};
    --bs-border-color-translucent: #384554;
    --bs-icon-bg: #{$gray-100};
    --bs-footer-bg:#{$gray-100};
    --bs-lightan: #19283a;
    --bs-footer-alt-bg :#{$gray-200};
    --bs-border-bottom-color:#252d35;
    --bs-footer-bg-dark:#1e2835;

    --bs-bg-light: #{$gray-200};

}