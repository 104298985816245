/* 
// 04.Components
*/

// container

.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
    @media (min-width: 1200px){
        max-width: 1140px;
    }
}

.row{
    > * {
        position: relative;
    }
}
  
.btn {
    border-radius: 3px;
    padding: 12px 30px;
    font-size: 15px !important;
    transition: all 0.5s;
    font-weight: 600;
    &:focus {
        box-shadow: none !important;
    }
    &:hover {
        transform: translateY(-2px);
        transition: all 0.5s;
    }
    &.btn-lg {
        padding: 14px 30px !important;
        font-size: 16px !important;
    }
    &.btn-sm {
        padding: 8px 20px !important;
        font-size: 14px !important;
    }
    @each $color,
    $value in $theme-colors {
        &.btn-#{$color} {
            background-color: #{$value} !important;
            border: 1px solid #{$value} !important;
            color: $white;
            box-shadow: 0 4px 20px rgba($value, .15);
            &:hover,
            &:focus,
            &.focus,
            &:active,
            &.active {
                background-color: #{darken($value, 3%)}!important;
                border:1px solid #{darken($value, 3%)}!important;
                box-shadow: 0 3px 10px rgba($value, 0.5);
                color: $white !important;
            }
        }
        &.btn-soft-#{$color} {
            background-color: #{lighten($value, 38%)}!important;
            color: $value;
            &:hover,
            &:focus,
            &.focus,
            &:active,
            &.active {
                box-shadow: 0 3px 12px rgba($value, .2);
                transform: translateY(-4px);
            }
        }
        &.btn-outline-#{$color} {
            border: 1px solid #{$value} !important;
            color: #{$value} !important;
            &:hover,
            &:focus,
            &.focus,
            &:active,
            &.active {
                background-color: #{$value} !important;
                border:1px solid #{$value} !important;
                box-shadow: 0 3px 10px rgba($value, 0.5);
                color: $white !important;
            }
        }
    }
    &.btn-light {
        background-color: $light;
        border: 1px solid $light;
        color: $primary !important;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: lighten($light, 3%);
            border: 1px solid lighten($light, 3%);
            box-shadow: 0 0 0 2px rgba($light, .08);
            color: $primary !important;
        }
    }
    &.btn-outline-light {
        border: 1px solid $light;
        color: $light !important;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $light;
            border: 1px solid $light;
            color: $dark !important;
        }
    }
}



// Badges

.badge {
    padding: 6px 14px;
    text-transform: uppercase;
        letter-spacing: 0.5px;
    &.badge-primary {
        background: rgba($primary, 0.1);
        box-shadow: 0 1px 1px rgba($primary, 0.5);
        color: $primary;
        
    }
}
@each $color, $value in $theme-colors {
    .badge {
        &.badge-#{$color} {
            background: #{$value};
            box-shadow: 0 1px 1px rgba($value, 0.5);
            color: $white;
        }
        &.badge-soft-#{$color} {
            background: rgba($value, 0.1);
            box-shadow: 0 1px 1px rgba($value, 0.5);
            color: $value;
        }
    }
}


// form-check-input

.form-check-input{
    cursor: pointer;
    &:checked{
        background-color: $primary;
        border-color: $primary;
    }
}

.form-check-label{
    cursor: pointer;
}


